import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Page } from '../../components/app/Page';
import { SEO } from '../../components/app/SEO';
import { GQLProjectQuinnPageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../../components/Typography';
import { FluidObject } from 'gatsby-image';
import { ProjectHeader } from '../../components/ProjectHeader';
import { Link } from '../../components/Link';
import { List, ListItem, ListItemText } from '../../components/List';
import { SocialLink } from '../../components/SocialLink';

type ClassKey = 'main';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: theme.breakpoints.width('md'),
        margin: '0 auto',
        width: '100%'
    },
}));

export type TProjectQuinnP = {
    data: GQLProjectQuinnPageQuery;
};

function ProjectQuinn(props: TReactFCP<TProjectQuinnP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, projectsYaml }: GQLProjectQuinnPageQuery = props.data;
    const {
        title, category, type, spoiler, description, stack, links, featuredImage
    }: NonNullable<GQLProjectQuinnPageQuery['projectsYaml']> = projectsYaml!;
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={title!}
                seoTitle={`Codename: ${title!}`}
                description={description!}
                image={{
                    src: featuredImage!.childImageSharp!.original!.src!,
                    width: featuredImage!.childImageSharp!.original!.width!,
                    height: featuredImage!.childImageSharp!.original!.height!
                }}
            />
            <ProjectHeader
                title={title!}
                category={category!}
                type={type!}
                spoiler={spoiler!}
                stack={stack! as string[]}
                homepage={links!.homepage!}
                repository={links!.repository!}
                featuredImage={{
                    originalSrc: featuredImage!.childImageSharp!.original!.src!,
                    fluid: featuredImage!.childImageSharp!.fluid! as FluidObject
                }}
            />
            <Typography variant='h2' align='center' gutterTop paragraph>About</Typography>
            <Typography paragraph>
                Quinn was born out of an effort to use <Link variant='external' to='https://qntfy.com/'>Qntfy's</Link>
                {` `}Natural Language Processing (NLP) technology (powering{` `}
                <Link variant='internal' to='/projects/ourdatahelps/'>OurDataHelps</Link>) in a team-focused setting
                rather than an individual one. The goal was to create a tool that could be used to foster healthier
                teams &mdash; ones where teams are more cohesive, transparent, and focused on building great culture.
            </Typography>
            <Typography paragraph>
                Since we had all of the NLP machine learning algorithms from OurDataHelps, the bulk of the effort was
                architecting a system which could
            </Typography>
            <List paragraph>
                <ListItem>
                    <ListItemText primary={`
                        Communicate with Slack's real time messaging, events, and rest APIs for team registration,
                        bot integration, message processing, and reporting
                    `} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`Scale well with many teams using the system at the same time`} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`Ensure the privacy of all users and teams using Quinn`} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`
                        Generate insightful reports which both quantify and qualify team well-being
                    `} />
                </ListItem>
            </List>
            <Typography paragraph>
                To ensure we could isolate bottlenecks and scale appropriately, the system adoped a microservice-based
                design roughly similar to systems that Qntfy had developed for DARPA. Components which interacted with
                Slack were split up into their own services &mdash; registering a team, collecting and processing
                messages, and generating reports. Most of the data pathways between services were handled by Kafka and
                temporarily persisted data aged off rapidly once scored by the analytics.
            </Typography>
            <Typography paragraph>
                The vast majority of the prototype was completed by a collegue and I in around 2-3 months amid our other
                projects, responsibilities, and commitments. The Founder and CEO of the company,{` `}
                <SocialLink variant='twitter' handle='glencoppersmith' disableBrandColor disableIcon enableUnderline>
                    Glen Coppersmith
                </SocialLink>
                , designed and built the reporting capability which was expanded upon by another collegue as we iterated
                futher on Quinn.
            </Typography>
            <Typography paragraph>
                After building the initial prototype, it was our belief that Quinn was a new, novel technology for
                measuring team health which led to us patenting the technology. I was honored to be named as an
                inventor on the patent and hope that Quinn is able to help build and maintain great teams in the future.
            </Typography>
            <Typography paragraph>
                This project gave me a great understanding of Slack's API and how to build products for it. Check out
                the <Link variant='internal' to='/projects/gtm-ctrl/'>GTM Ctrl</Link> project that I built for my
                motorsports club using the knowledge I gained building Quinn.
            </Typography>
        </main>
    );
}

export default (props: TReactFCP<TProjectQuinnP>): TReactFCR => (
    <Page component={ProjectQuinn} cprops={props} />
);

export const query = graphql`
    query ProjectQuinnPage {
        sitePage(internalComponentName: {eq: "ComponentProjectsQuinn"}) {
            path
        }
        projectsYaml(pid: {eq: "quinn"}) {
            title
            category
            type
            spoiler
            description
            stack
            links {
                homepage
                repository
            }
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    original {
                        src
                        width
                        height
                    }
                }
            }
        }
    }
`;
